import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ClientDashboardWrapper} from '../pages/client-dashboard/DashboardWrapper'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const RolePage = lazy(() => import('../modules/apps/role/RolePage'))
  const EmployeePage = lazy(() => import('../modules/apps/employee/EmployeePage'))
  const CategoryPage = lazy(() => import('../modules/apps/category/CategoryPage'))
  const SubCategoryPage = lazy(() => import('../modules/apps/subcategory/SubCategoryPage'))
  const UnitPage = lazy(() => import('../modules/apps/unit/UnitPage'))
  const VariantPage = lazy(() => import('../modules/apps/variant/VariantPage'))
  const SkuPage = lazy(() => import('../modules/apps/sku/SkuPage'))
  const ProductPage = lazy(() => import('../modules/apps/product/ProductPage'))
  const TaxPage = lazy(() => import('../modules/apps/tax/TaxPage'))
  const BannerPage = lazy(() => import('../modules/apps/banner/BannerPage'))
  const OrderPage = lazy(() => import('../modules/apps/order/OrderPage'))

  const RoleAccessPage = lazy(() => import('../modules/apps/role-access/RoleaccessPage'))

  const {currentUser} = useAuth()
  let too = ''
  if (currentUser?.employeeType === 1) {
     too = `/dashboard`
  }
  if (currentUser?.employeeType === 2) {
     too = `/tuymai-dashboard`
  }
  return (
    <Suspense fallback={<SuspendedView />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to={too} />} />

          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='crematorium-dashboard' element={<ClientDashboardWrapper />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />

          {/* Lazy Modules */}
          <Route
            path='roles/*'
            element={
              <SuspendedView>
                <RolePage />
              </SuspendedView>
            }
          />
          <Route
            path='employees/*'
            element={
              <SuspendedView>
                <EmployeePage />
              </SuspendedView>
            }
          />
         
          <Route
            path='page-access/*'
            element={
              <SuspendedView>
                <RoleAccessPage />
              </SuspendedView>
            }
          />
          <Route
            path='categorys/*'
            element={
              <SuspendedView>
                <CategoryPage />
              </SuspendedView>
            }
          />
           <Route
            path='subcategorys/*'
            element={
              <SuspendedView>
                <SubCategoryPage />
              </SuspendedView>
            }
          />
           <Route
            path='units/*'
            element={
              <SuspendedView>
                <UnitPage />
              </SuspendedView>
            }
          />
          <Route
            path='variants/*'
            element={
              <SuspendedView>
                <VariantPage />
              </SuspendedView>
            }
          />
           <Route
            path='skus/*'
            element={
              <SuspendedView>
                <SkuPage />
              </SuspendedView>
            }
          />
           <Route
            path='products/*'
            element={
              <SuspendedView>
                <ProductPage />
              </SuspendedView>
            }
          />
         <Route
            path='taxs/*'
            element={
              <SuspendedView>
                <TaxPage />
              </SuspendedView>
            }
          />
           <Route
            path='banners/*'
            element={
              <SuspendedView>
                <BannerPage />
              </SuspendedView>
            }
          />
          <Route
            path='orders/*'
            element={
              <SuspendedView>
                <OrderPage />
              </SuspendedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
